import React from 'react';
import { Route, Routes as RoutesComponent } from 'react-router-dom';
import { Auth } from './modules/auth/Auth';

import { AuthFacebookCallback } from './modules/auth/AuthFacebookCallback';
import { Session } from './modules/session/Session';
import { Layout } from './shared/components/layout/user/Layout';
import { AuthLogout } from './modules/auth/AuthLogout';
import { SettingAccountView } from './modules/settings/SettingAccountView';
import { LayoutAuth } from './shared/components/layout/auth/LayoutAuth';
import { useAuth } from './modules/auth/auth-hook';
import { MainLoader } from './shared/components/MainLoader';
import { Plans } from './modules/plan/Plans';
import { PaymentMethod } from './modules/payment/PaymentMethod';
import { RoleAuthorization } from './modules/rbac/RoleAuthorization';
import { RBACPermissions } from './modules/rbac/rbac-types';
import { Invoices } from './modules/invoice/Invoices';
import { Subscriptions } from './modules/subscriptions/Subscriptions';
import { Card } from './modules/card/Card';
import { Notifications } from './modules/notification/Notifications';
import { ErrorView } from './modules/rbac/ErrorView';
import { UserChangeEmail } from './modules/user/UserChangeEmail';
//import { SavedNotes } from './modules/savedNotes/SavedNotes';
//import { Newsletters } from './modules/newsletter/Newsletters';

export const Routes: React.FC = () => {
  const { isLoading } = useAuth();

  return isLoading ? (
    <MainLoader />
  ) : (
    <RoutesComponent>
      <Route
        path="/autenticacion"
        element={
          <LayoutAuth>
            <Auth />
          </LayoutAuth>
        }
      />
      <Route path="/logout" element={<AuthLogout />} />
      <Route path="/change-email" element={<UserChangeEmail />} />
      <Route path="/callback/facebook" element={<AuthFacebookCallback />} />
      <Route
        path="/suscripciones"
        element={
          <RoleAuthorization
            render={() => <Plans />}
            permission={RBACPermissions.CAN_SUBSCRIPTION}
            error={() => <ErrorView text="Ya tienes una suscripción activa" />}
          />
        }
      />

      <Route
        path="*"
        element={
          <Session>
            <RoutesComponent>
              <Route
                path="/"
                element={
                  <Layout>
                    <SettingAccountView />
                  </Layout>
                }
              />
              <Route
                path="/transacciones"
                element={
                  <Layout>
                    <Invoices />
                  </Layout>
                }
              />
              <Route
                path="/biblioteca"
                element={
                  <Layout>
                    <Subscriptions />
                  </Layout>
                }
              />
              <Route
                path="/detalles-de-facturacion"
                element={
                  <Layout>
                    <Card />
                  </Layout>
                }
              />
              <Route
                path="/notificaciones"
                element={
                  <Layout>
                    <Notifications />
                  </Layout>
                }
              />
              {/**
               * <Route
                path="/newsletters"
                element={
                  <Layout>
                    <Newsletters />
                  </Layout>
                }
              />
               */}
              {/**
                 * <Route
                path="/mis-notas-guardadas"
                element={
                  <Layout>
                    <SavedNotes />
                  </Layout>
                }
              />
                 */}
              <Route
                path="/metodos-de-pago/:id"
                element={
                  <RoleAuthorization
                    render={() => (
                      <Layout showSidebar={false} showNotification={false}>
                        <PaymentMethod />
                      </Layout>
                    )}
                    permission={RBACPermissions.CAN_SUBSCRIPTION}
                    error={() => (
                      <ErrorView text="Ya tienes una suscripción activa" />
                    )}
                  />
                }
              />
            </RoutesComponent>
          </Session>
        }
      />
    </RoutesComponent>
  );
};
