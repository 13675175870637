import React from 'react';
import { Box, Typography } from '@mui/material';

export const FooterAuth: React.FC = () => {
  return (
    <Box
      style={{
        backgroundColor: 'white !important',
        width: '100%',
      }}
    >
      <Box
        justifyContent={'center'}
        alignItems={'center'}
        display={'flex'}
        width={'100%'}
        component={'div'}
        style={{ height: '80px', background: 'white' }}
      >
        <Box width={'100%'} padding={'0px 50px'}>
          <Typography fontSize={16} color={'#999999'} textAlign={'center'}>
            Conoce más de nuestros
          </Typography>
          <Box
            display={'inline-flex'}
            flexWrap={'wrap'}
            justifyContent={'center'}
            justifyItems={'center'}
            textAlign={'center'}
            width={'100%'}
          >
            <a
              style={{
                color: '#777777',
                cursor: 'pointer',
                fontWeight: 700,
                textDecoration: 'none',
              }}
              target="_blank"
            >
              {'Términos de uso'}
            </a>

            <Box fontSize={16} component="span" mr={1} ml={1} color="#777777">
              {' y '}
            </Box>
            <a
              style={{
                color: '#777777',
                cursor: 'pointer',
                fontWeight: 700,
                textDecoration: 'none',
              }}
              target="_blank"
            >
              {'Política de Privacidad'}
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
