import React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      width: 'fit-content',
    },
  }),
);

type BackButtonProps = {
  onClick: () => void;
  text?: string;
};

export const BackButton: React.FC<BackButtonProps> = ({
  onClick,
  text = 'atrás',
}) => {
  const classes = useStyles();

  return (
    <Box
      onClick={() => onClick()}
      className={classes.container}
      component="span"
    >
      <Box>
        <ArrowBackIosIcon
          fontSize="small"
          style={{ color: 'rgb(153, 153, 153)' }}
        />
      </Box>
      <Typography
        textTransform="uppercase"
        color="rgb(153, 153, 153)"
        variant="subtitle2"
      >
        {text}
      </Typography>
    </Box>
  );
};
