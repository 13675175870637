import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useAuth } from '../auth/auth-hook';
import { PaymentMethod } from '../../shared/types/generated';
import { CardItem } from './components/CardItem';
import { CardSection } from '../../shared/components/card/CardSection';

export const Card: React.FC = () => {
  const { user } = useAuth();
  const subscription = user?.subscriptions?.length
    ? user?.subscriptions[0]
    : undefined;

  let content: JSX.Element;

  if (subscription?.payment_method === PaymentMethod.Openpay) {
    content = (
      <Box>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight={'800'}>Tarjeta de pago</Typography>
            <Box>
              Elija una tarjeta de crédito y la usaremos para pagar su
              suscripción
            </Box>
          </Grid>
          <Grid item xs={12}>
            <CardItem />
          </Grid>
        </Grid>
      </Box>
    );
  } else if (subscription?.payment_method === PaymentMethod.Paypal) {
    content = (
      <Box>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Box display="flex">
              <img
                alt="icon-Card"
                src={'/paypal.svg'}
                width={20}
                height={20}
                style={{ marginRight: 10 }}
              />

              <Box>Se uso paypal para pagar su {subscription?.plan?.title}</Box>
            </Box>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Box>
    );
  } else if (subscription?.payment_method) {
    content = <Box>Actualmente cuentas con una suscripción de cortesía.</Box>;
  } else {
    content = (
      <Box>
        Actualmente no cuentas con una suscripción, te invitamos a ser parte de
        la comunidad Premium.
      </Box>
    );
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <CardSection
          title={
            <Typography fontWeight={'800'}>Detalles de facturación</Typography>
          }
        >
          <Box p={2}>{content}</Box>
        </CardSection>
      </Grid>
    </Grid>
  );
};
