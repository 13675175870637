import React, { useRef } from 'react';
import { Avatar, Box, Theme, Typography } from '@mui/material';
import { useAuth } from '../../auth/auth-hook';
import { createStyles, makeStyles } from '@mui/styles';
import { useStore } from '@cobuildlab/react-simple-state';
import { changeAvatarStore, updateProfileStore } from '../setting-events';
import { changeAvatarAction } from '../setting-actions';
import { PRIMARY_COLOR } from '../../../shared/css/theme';
import { EditOutlined } from '@mui/icons-material';
import { CardSection } from '../../../shared/components/card/CardSection';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    divider: {
      borderWidth: 2,
      borderColor: 'black!important',
      marginTop: '15px!important',
      marginBottom: '15px!important',
    },
    text: {
      color: PRIMARY_COLOR,
    },
    edit: {
      cursor: 'pointer',
    },
    button: {
      background: '#FE0000!important',
      border: 'none!important',
      cursor: 'pointer',
      padding: '0!important',
      width: '22px!important',
      height: '22px!important',
      borderRadius: '100%!important',
      color: 'white',
    },
  }),
);
export const SettingAccountAvatar: React.FC = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const refFile = useRef<HTMLInputElement | null>(null);
  const { file } = useStore(changeAvatarStore);
  const { isChanged } = useStore(updateProfileStore);

  return (
    <Box mb={2}>
      <CardSection
        title={
          <Typography variant="h6" fontWeight={800}>
            Hola y bienvenido{' '}
            <span className={classes.text}>
              {decodeURI(user?.name as string) ||
                decodeURI(user?.email as string)}
            </span>
          </Typography>
        }
        subheader={
          <Typography variant="subtitle1">
            Acá encontrarás información detallada de tu cuenta.
          </Typography>
        }
        avatar={
          <Box display={'flex'} alignItems={'center'}>
            <Box position={'relative'}>
              <Avatar
                onClick={() => isChanged && refFile.current?.click()}
                style={{ cursor: isChanged ? 'pointer' : 'inherit' }}
                sx={{ width: 60, height: 60 }}
                src={
                  file ? URL.createObjectURL(file) : (user?.avatar as string)
                }
              />
              <Box width={'22px'} position={'absolute'} bottom={-5}>
                {isChanged ? (
                  <button
                    className={classes.button}
                    onClick={() => refFile.current?.click()}
                  >
                    <EditOutlined fontSize="small" />
                  </button>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Box>
        }
      />
      <input
        type="file"
        hidden
        ref={refFile}
        onChange={(event) => {
          changeAvatarAction(
            event.target.files && event.target.files.length
              ? event.target.files[0]
              : null,
          );
        }}
      />
    </Box>
  );
};
