/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type CancelSubscriptionInput = {
  subscription_id?: InputMaybe<Scalars['Int']>;
};

export type CancelSubscriptionResponse = {
  __typename?: 'CancelSubscriptionResponse';
  subscription?: Maybe<Subscription>;
};

export type Card = {
  __typename?: 'Card';
  bank_code?: Maybe<Scalars['String']>;
  bank_name?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  card_number?: Maybe<Scalars['String']>;
  customer_card_id?: Maybe<Scalars['String']>;
  expiration_month?: Maybe<Scalars['String']>;
  expiration_year?: Maybe<Scalars['String']>;
  holder_name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ConnectResponse = {
  __typename?: 'ConnectResponse';
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type ConnectUserAppleInput = {
  token?: InputMaybe<Scalars['String']>;
};

export type ConnectUserFacebookInput = {
  token?: InputMaybe<Scalars['String']>;
};

export type ConnectUserGoogleInput = {
  token?: InputMaybe<Scalars['String']>;
};

export type CreateOpenPayChargeInput = {
  deviceDataId?: InputMaybe<Scalars['String']>;
  planId?: InputMaybe<Scalars['Int']>;
  token?: InputMaybe<Scalars['String']>;
};

export type CreateOpenPayChargeResponse = {
  __typename?: 'CreateOpenPayChargeResponse';
  redirect_url?: Maybe<Scalars['String']>;
};

export type CreateOpenPaySubscriptionInput = {
  deviceDataId?: InputMaybe<Scalars['String']>;
  planId?: InputMaybe<Scalars['Int']>;
  token?: InputMaybe<Scalars['String']>;
};

export type CreateOpenPaySubscriptionResponse = {
  __typename?: 'CreateOpenPaySubscriptionResponse';
  subscription?: Maybe<Subscription>;
};

export type CreatePaypalSubscriptionInput = {
  orderId?: InputMaybe<Scalars['String']>;
  planId?: InputMaybe<Scalars['Int']>;
  subscriptionId?: InputMaybe<Scalars['String']>;
};

export type CreatePaypalSubscriptionResponse = {
  __typename?: 'CreatePaypalSubscriptionResponse';
  subscription?: Maybe<Subscription>;
};

export type CreateUserSavedPostInput = {
  post_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

export type CreatedAtInput = {
  gte?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
};

export type DeleteUserSavedPostInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type DisableUserInput = {
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type DisconnectResponse = {
  __typename?: 'DisconnectResponse';
  message?: Maybe<Scalars['String']>;
};

export type DisconnectUserProviderInput = {
  provider?: InputMaybe<SocialProviders>;
};

export enum Education {
  Graduate = 'GRADUATE',
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Superior = 'SUPERIOR',
}

export type File = {
  encoding: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
};

export type ForgotPasswordPayload = {
  __typename?: 'ForgotPasswordPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  NoApply = 'NO_APPLY',
}

export type Invoice = {
  __typename?: 'Invoice';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  plan?: Maybe<Plan>;
  status?: Maybe<InvoiceStatuses>;
  transaction_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export enum InvoiceStatuses {
  Cancelled = 'CANCELLED',
  Paid = 'PAID',
  PaymentPending = 'PAYMENT_PENDING',
  Refunded = 'REFUNDED',
  Unpaid = 'UNPAID',
}

export type IterAuthorRelatedPostConnection = {
  __typename?: 'IterAuthorRelatedPostConnection';
  edges?: Maybe<Array<Maybe<IterAuthorRelatedPostConnectionNode>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type IterAuthorRelatedPostConnectionNode = {
  __typename?: 'IterAuthorRelatedPostConnectionNode';
  node?: Maybe<IterPost>;
};

export type IterAuthorRelatedPostsWhere = {
  category_id?: InputMaybe<Scalars['String']>;
};

export type IterHighlightedPostsWhere = {
  section?: InputMaybe<Scalars['String']>;
};

export type IterInternalSection = {
  __typename?: 'IterInternalSection';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type IterPost = {
  __typename?: 'IterPost';
  author?: Maybe<IterPostAuthor>;
  categories?: Maybe<Array<Maybe<IterPostCategory>>>;
  content?: Maybe<Array<Maybe<IterPostComponent>>>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  reactions?: Maybe<Scalars['Int']>;
  readingTime?: Maybe<Scalars['String']>;
  topics?: Maybe<Array<Maybe<IterPostTopic>>>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type IterPostAuthor = {
  __typename?: 'IterPostAuthor';
  content?: Maybe<Array<Maybe<IterPostComponent>>>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type IterPostCategory = {
  __typename?: 'IterPostCategory';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type IterPostComponent = {
  __typename?: 'IterPostComponent';
  component?: Maybe<Array<Maybe<IterPostComponentContent>>>;
  name?: Maybe<IterPostComponentName>;
  remoteContent?: Maybe<IterRemoteContent>;
  text?: Maybe<Scalars['String']>;
};

export type IterPostComponentContent = {
  __typename?: 'IterPostComponentContent';
  name?: Maybe<IterPostComponentName>;
  text?: Maybe<Scalars['String']>;
};

export enum IterPostComponentName {
  Boton = 'Boton',
  Byline = 'Byline',
  Cutline = 'Cutline',
  EntrevistaPregunta = 'Entrevista_Pregunta',
  FacebookText = 'Facebook_Text',
  FichaFoto = 'Ficha_Foto',
  FichaSocial = 'Ficha_Social',
  FichaTexto = 'Ficha_Texto',
  FichaTitulo = 'Ficha_Titulo',
  FlourishText = 'Flourish_Text',
  HtmlText = 'HTML_Text',
  Headline = 'Headline',
  Image = 'Image',
  ImageText = 'Image_Text',
  ImagenColumnista = 'ImagenColumnista',
  InstagramText = 'Instagram_Text',
  Lead = 'Lead',
  LinkText = 'Link_Text',
  MultimediaText = 'Multimedia_Text',
  ParrafoLista = 'Parrafo_Lista',
  Quote = 'Quote',
  QuoteByline = 'Quote_byline',
  Subheadline = 'Subheadline',
  TeaserHeadline = 'TeaserHeadline',
  TeaserImage = 'TeaserImage',
  TeaserText = 'TeaserText',
  Text = 'Text',
  TikTokText = 'TikTok_Text',
  TituloApp = 'Titulo_App',
  TituloNews = 'Titulo_News',
  Topheadline = 'Topheadline',
  TwitterText = 'Twitter_Text',
  YoutubeText = 'Youtube_Text',
  EmailAutor = 'emailAutor',
  ImageList = 'image_list',
  LinkIntTitle = 'linkIntTitle',
  LinkIntUrl = 'linkIntURL',
  TextList = 'text_list',
  TitleList = 'title_list',
  TwitterProfile = 'twitterProfile',
}

export type IterPostConnection = {
  __typename?: 'IterPostConnection';
  edges?: Maybe<Array<Maybe<IterPostConnectionNode>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type IterPostConnectionNode = {
  __typename?: 'IterPostConnectionNode';
  node?: Maybe<IterPost>;
};

export type IterPostResponse = {
  __typename?: 'IterPostResponse';
  post?: Maybe<IterPost>;
};

export type IterPostTopic = {
  __typename?: 'IterPostTopic';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type IterPostWhere = {
  about?: InputMaybe<Scalars['String']>;
  post_id?: InputMaybe<Scalars['String']>;
};

export type IterPostsBySectionResponse = {
  __typename?: 'IterPostsBySectionResponse';
  items?: Maybe<Array<Maybe<IterPost>>>;
};

export type IterPostsBySectionWhere = {
  slug?: InputMaybe<Scalars['String']>;
};

export type IterPostsResponse = {
  __typename?: 'IterPostsResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<IterPost>>>;
};

export type IterPostsWhere = {
  category_id?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type IterRemoteContent = {
  __typename?: 'IterRemoteContent';
  href?: Maybe<Scalars['String']>;
  rendition?: Maybe<Scalars['String']>;
};

export type IterSearchPost = {
  __typename?: 'IterSearchPost';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type IterSearchPostConnection = {
  __typename?: 'IterSearchPostConnection';
  edges?: Maybe<Array<Maybe<IterSearchPostConnectionNode>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type IterSearchPostConnectionNode = {
  __typename?: 'IterSearchPostConnectionNode';
  node?: Maybe<IterSearchPost>;
};

export type IterSearchPostsWhere = {
  category_id?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export enum IterSearchSortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type IterSection = {
  __typename?: 'IterSection';
  id?: Maybe<Scalars['Int']>;
  internals?: Maybe<Array<Maybe<IterInternalSection>>>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type IterSectionResponse = {
  __typename?: 'IterSectionResponse';
  items?: Maybe<Array<Maybe<IterSection>>>;
};

export type IterSectionsWithPosts = {
  __typename?: 'IterSectionsWithPosts';
  items?: Maybe<Array<Maybe<IterPost>>>;
  section?: Maybe<IterSection>;
};

export type IterTrendingPostsWhere = {
  section?: InputMaybe<Scalars['String']>;
};

export type MailchimpAddSubscriptionInput = {
  audience?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type MailchimpAddSubscriptionResponse = {
  __typename?: 'MailchimpAddSubscriptionResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type MailchimpAudience = {
  __typename?: 'MailchimpAudience';
  audience?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_subscribed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type MailchimpAudienceResponse = {
  __typename?: 'MailchimpAudienceResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<MailchimpAudience>>>;
};

export type MailchimpSubscription = {
  __typename?: 'MailchimpSubscription';
  audience?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  unique_email_id?: Maybe<Scalars['String']>;
};

export type MailchimpSubscriptionResponse = {
  __typename?: 'MailchimpSubscriptionResponse';
  subscriptions?: Maybe<Array<Maybe<MailchimpSubscription>>>;
};

export type MailchimpSubscriptionWhere = {
  email?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  appleSignup?: Maybe<AuthPayload>;
  cancelSubscription?: Maybe<CancelSubscriptionResponse>;
  changePassword?: Maybe<AuthPayload>;
  connectUserApple?: Maybe<ConnectResponse>;
  connectUserFacebook?: Maybe<ConnectResponse>;
  connectUserGoogle?: Maybe<ConnectResponse>;
  createOpenPayCharge?: Maybe<CreateOpenPayChargeResponse>;
  createOpenPaySubscription?: Maybe<CreateOpenPaySubscriptionResponse>;
  createPaypalSubscription?: Maybe<CreatePaypalSubscriptionResponse>;
  createUserSavedPost?: Maybe<UserSavedPost>;
  deleteUserSavedPost?: Maybe<UserSavedPost>;
  disableAccount?: Maybe<DisableAccountResponse>;
  disconnectUserProvider?: Maybe<DisconnectResponse>;
  facebookSignup?: Maybe<AuthPayload>;
  forgotPassword?: Maybe<ForgotPasswordPayload>;
  googleSignup?: Maybe<AuthPayload>;
  login?: Maybe<AuthPayload>;
  mailchimpAddSubscription?: Maybe<MailchimpAddSubscriptionResponse>;
  recaptcha?: Maybe<RecaptchaResponse>;
  signup?: Maybe<AuthPayload>;
  updateCard?: Maybe<UpdateCardResponse>;
  updateUser?: Maybe<User>;
  updateUserEmail?: Maybe<UpdateUserEmailResponse>;
};

export type MutationAppleSignupArgs = {
  token: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};

export type MutationCancelSubscriptionArgs = {
  data?: InputMaybe<CancelSubscriptionInput>;
};

export type MutationChangePasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationConnectUserAppleArgs = {
  data: ConnectUserAppleInput;
};

export type MutationConnectUserFacebookArgs = {
  data: ConnectUserFacebookInput;
};

export type MutationConnectUserGoogleArgs = {
  data: ConnectUserGoogleInput;
};

export type MutationCreateOpenPayChargeArgs = {
  data?: InputMaybe<CreateOpenPayChargeInput>;
};

export type MutationCreateOpenPaySubscriptionArgs = {
  data?: InputMaybe<CreateOpenPaySubscriptionInput>;
};

export type MutationCreatePaypalSubscriptionArgs = {
  data?: InputMaybe<CreatePaypalSubscriptionInput>;
};

export type MutationCreateUserSavedPostArgs = {
  data?: InputMaybe<CreateUserSavedPostInput>;
};

export type MutationDeleteUserSavedPostArgs = {
  data?: InputMaybe<DeleteUserSavedPostInput>;
};

export type MutationDisableAccountArgs = {
  data?: InputMaybe<DisableUserInput>;
};

export type MutationDisconnectUserProviderArgs = {
  data: DisconnectUserProviderInput;
};

export type MutationFacebookSignupArgs = {
  token: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
  form: Scalars['String'];
  redirectUrl: Scalars['String'];
};

export type MutationGoogleSignupArgs = {
  token: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};

export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationMailchimpAddSubscriptionArgs = {
  data?: InputMaybe<MailchimpAddSubscriptionInput>;
};

export type MutationRecaptchaArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};

export type MutationSignupArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  url: Scalars['String'];
};

export type MutationUpdateCardArgs = {
  data?: InputMaybe<UpdateCardInput>;
};

export type MutationUpdateUserArgs = {
  data?: InputMaybe<UpdateUserInput>;
};

export type MutationUpdateUserEmailArgs = {
  data?: InputMaybe<UpdateUserEmailInput>;
};

export type Notification = {
  __typename?: 'Notification';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type NotificationResponse = {
  __typename?: 'NotificationResponse';
  count?: Maybe<Scalars['Int']>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
};

export type OpenPay = {
  __typename?: 'OpenPay';
  first_payment_price?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  open_pay_id?: Maybe<Scalars['String']>;
  plan_id?: Maybe<Scalars['Int']>;
  status?: Maybe<OpenPayStatuses>;
};

export enum OpenPayStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export enum PaymentMethod {
  Openpay = 'OPENPAY',
  Paypal = 'PAYPAL',
}

export type Paypal = {
  __typename?: 'Paypal';
  id?: Maybe<Scalars['Int']>;
  paypal_id?: Maybe<Scalars['String']>;
  plan_id?: Maybe<Scalars['Int']>;
  status?: Maybe<PaypalStatuses>;
};

export enum PaypalStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type Plan = {
  __typename?: 'Plan';
  description?: Maybe<Scalars['String']>;
  helper_text?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  open_pay?: Maybe<OpenPay>;
  paypal?: Maybe<Paypal>;
  price?: Maybe<Scalars['String']>;
  status?: Maybe<PlanStatuses>;
  sub_title?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PlanResponse = {
  __typename?: 'PlanResponse';
  items?: Maybe<Array<Maybe<Plan>>>;
};

export enum PlanStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type PlanWhereInput = {
  status?: InputMaybe<PaypalStatuses>;
};

export type PlansWhereInput = {
  status?: InputMaybe<PaypalStatuses>;
};

export type Query = {
  __typename?: 'Query';
  currentUser?: Maybe<User>;
  mailchimpAudiences?: Maybe<MailchimpAudienceResponse>;
  mailchimpSubscriptions?: Maybe<MailchimpSubscriptionResponse>;
  notificationList?: Maybe<NotificationResponse>;
  plan?: Maybe<Plan>;
  plans?: Maybe<PlanResponse>;
  userProviders?: Maybe<UserProviderPayload>;
  userSavedIterPosts?: Maybe<UserSavedIterPostsResponse>;
  userSavedPosts?: Maybe<UserSavedPostsResponse>;
  users?: Maybe<UsersPayload>;
};

export type QueryMailchimpSubscriptionsArgs = {
  where?: InputMaybe<MailchimpSubscriptionWhere>;
};

export type QueryNotificationListArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationsWhere>;
};

export type QueryPlanArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryPlansArgs = {
  where?: InputMaybe<PlansWhereInput>;
};

export type QueryUserProvidersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserProvidersWhere>;
};

export type QueryUserSavedIterPostsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSavedIterPostsWhere>;
};

export type QueryUserSavedPostsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSavedPostsWhere>;
};

export type QueryUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UsersWhere>;
};

export type RecaptchaResponse = {
  __typename?: 'RecaptchaResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export enum SocialProviders {
  Apple = 'APPLE',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  System = 'SYSTEM',
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  cancel_date?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_cancel?: Maybe<Scalars['Boolean']>;
  payment_method?: Maybe<PaymentMethod>;
  period_end_date?: Maybe<Scalars['String']>;
  period_start_date?: Maybe<Scalars['String']>;
  plan?: Maybe<Plan>;
  status?: Maybe<SubscriptionStatuses>;
  updated_at?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export enum SubscriptionStatuses {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Inactive = 'INACTIVE',
}

export type UpdateCardInput = {
  card_number?: InputMaybe<Scalars['String']>;
  cvv2?: InputMaybe<Scalars['String']>;
  device_session_id?: InputMaybe<Scalars['String']>;
  expiration_month?: InputMaybe<Scalars['String']>;
  expiration_year?: InputMaybe<Scalars['String']>;
  holder_name?: InputMaybe<Scalars['String']>;
};

export type UpdateCardResponse = {
  __typename?: 'UpdateCardResponse';
  card?: Maybe<Card>;
};

export type UpdateUserEmailInput = {
  new_email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type UpdateUserEmailResponse = {
  __typename?: 'UpdateUserEmailResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserInput = {
  file?: InputMaybe<Scalars['Upload']>;
  user?: InputMaybe<UserInput>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  card?: Maybe<Card>;
  created_at?: Maybe<Scalars['String']>;
  education?: Maybe<Education>;
  email: Scalars['String'];
  gender?: Maybe<Gender>;
  id: Scalars['Int'];
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  name: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  social_providers?: Maybe<Array<Maybe<UserSocialProviders>>>;
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
  updated_at?: Maybe<Scalars['String']>;
};

export type UserInput = {
  avatar?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['String']>;
  education?: InputMaybe<Education>;
  email: Scalars['String'];
  email_verified_at?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  id: Scalars['Int'];
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  remember_token?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['String']>;
};

export type UserProviderPayload = {
  __typename?: 'UserProviderPayload';
  count?: Maybe<Scalars['Int']>;
  userProviders?: Maybe<Array<Maybe<UserSocialProviders>>>;
};

export type UserProvidersWhere = {
  created_at?: InputMaybe<CreatedAtInput>;
  provider?: InputMaybe<SocialProviders>;
};

export type UserRole = {
  __typename?: 'UserRole';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<UserRoles>;
};

export enum UserRoles {
  Suscriptor = 'SUSCRIPTOR',
  User = 'USER',
}

export type UserSavedIterPostsResponse = {
  __typename?: 'UserSavedIterPostsResponse';
  count?: Maybe<Scalars['Int']>;
  posts?: Maybe<Array<Maybe<IterPost>>>;
};

export type UserSavedIterPostsWhere = {
  post_id?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['Int']>;
  user_saved_post_folder_id?: InputMaybe<Scalars['Int']>;
};

export type UserSavedPost = {
  __typename?: 'UserSavedPost';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  post_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type UserSavedPostRestApi = {
  __typename?: 'UserSavedPostRestApi';
  id?: Maybe<Scalars['Int']>;
  post_content?: Maybe<Scalars['String']>;
  post_date?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['Int']>;
  post_image?: Maybe<Scalars['String']>;
  post_name?: Maybe<Scalars['String']>;
  post_terms?: Maybe<Array<Maybe<Scalars['String']>>>;
  post_title?: Maybe<Scalars['String']>;
  post_type?: Maybe<Scalars['String']>;
  post_uri?: Maybe<Scalars['String']>;
  post_url?: Maybe<Scalars['String']>;
  sub_post_type?: Maybe<Scalars['String']>;
};

export type UserSavedPostsResponse = {
  __typename?: 'UserSavedPostsResponse';
  count?: Maybe<Scalars['Int']>;
  posts?: Maybe<Array<Maybe<UserSavedPostRestApi>>>;
};

export type UserSavedPostsWhere = {
  user_id?: InputMaybe<Scalars['Int']>;
};

export type UserSocialProviders = {
  __typename?: 'UserSocialProviders';
  created_at?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  primary?: Maybe<Scalars['Boolean']>;
  provider?: Maybe<SocialProviders>;
  provider_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  url_register?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type UsersPayload = {
  __typename?: 'UsersPayload';
  count?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type UsersWhere = {
  created_at?: InputMaybe<CreatedAtInput>;
  name?: InputMaybe<StringFilter>;
};

export type DisableAccountResponse = {
  __typename?: 'disableAccountResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type IterRankingPostsWhere = {
  limit?: InputMaybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type NotificationsWhere = {
  user_id?: InputMaybe<Scalars['Int']>;
};

export type UpdateCardMutationVariables = Exact<{
  data?: InputMaybe<UpdateCardInput>;
}>;

export type UpdateCardMutation = {
  __typename?: 'Mutation';
  updateCard: {
    __typename?: 'UpdateCardResponse';
    card: {
      __typename?: 'Card';
      type: string | null;
      brand: string | null;
      card_number: string | null;
      holder_name: string | null;
      expiration_year: string | null;
      expiration_month: string | null;
      bank_name: string | null;
      bank_code: string | null;
    } | null;
  } | null;
};

export type MailchimpAddSubscriptionMutationVariables = Exact<{
  data?: InputMaybe<MailchimpAddSubscriptionInput>;
}>;

export type MailchimpAddSubscriptionMutation = {
  __typename?: 'Mutation';
  mailchimpAddSubscription: {
    __typename?: 'MailchimpAddSubscriptionResponse';
    success: boolean | null;
  } | null;
};

export type MailchimpAudiencesQueryVariables = Exact<{
  where?: InputMaybe<MailchimpSubscriptionWhere>;
}>;

export type MailchimpAudiencesQuery = {
  __typename?: 'Query';
  mailchimpAudiences: {
    __typename?: 'MailchimpAudienceResponse';
    count: number | null;
    items: Array<{
      __typename?: 'MailchimpAudience';
      audience: string | null;
      id: number | null;
      is_subscribed: boolean | null;
      name: string | null;
      description: string | null;
    } | null> | null;
  } | null;
  mailchimpSubscriptions: {
    __typename?: 'MailchimpSubscriptionResponse';
    subscriptions: Array<{
      __typename?: 'MailchimpSubscription';
      unique_email_id: string | null;
      status: string | null;
      audience: string | null;
    } | null> | null;
  } | null;
};

export type NotificationsQueryVariables = Exact<{
  where?: InputMaybe<NotificationsWhere>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type NotificationsQuery = {
  __typename?: 'Query';
  notificationList: {
    __typename?: 'NotificationResponse';
    count: number | null;
    notifications: Array<{
      __typename?: 'Notification';
      id: string | null;
      message: string | null;
      created_at: string | null;
    } | null> | null;
  } | null;
};

export type CreateOpenPaySubscriptionMutationVariables = Exact<{
  data?: InputMaybe<CreateOpenPaySubscriptionInput>;
}>;

export type CreateOpenPaySubscriptionMutation = {
  __typename?: 'Mutation';
  createOpenPaySubscription: {
    __typename?: 'CreateOpenPaySubscriptionResponse';
    subscription: {
      __typename?: 'Subscription';
      id: number | null;
      status: SubscriptionStatuses | null;
      customer_id: string | null;
      period_end_date: string | null;
      period_start_date: string | null;
      created_at: string | null;
      updated_at: string | null;
    } | null;
  } | null;
};

export type CreateOpenPayChargeMutationVariables = Exact<{
  data?: InputMaybe<CreateOpenPayChargeInput>;
}>;

export type CreateOpenPayChargeMutation = {
  __typename?: 'Mutation';
  createOpenPayCharge: {
    __typename?: 'CreateOpenPayChargeResponse';
    redirect_url: string | null;
  } | null;
};

export type CreatePaypalSubscriptionMutationVariables = Exact<{
  data?: InputMaybe<CreatePaypalSubscriptionInput>;
}>;

export type CreatePaypalSubscriptionMutation = {
  __typename?: 'Mutation';
  createPaypalSubscription: {
    __typename?: 'CreatePaypalSubscriptionResponse';
    subscription: {
      __typename?: 'Subscription';
      id: number | null;
      status: SubscriptionStatuses | null;
      customer_id: string | null;
      period_end_date: string | null;
      period_start_date: string | null;
      cancel_date: string | null;
      is_cancel: boolean | null;
      payment_method: PaymentMethod | null;
      created_at: string | null;
      updated_at: string | null;
    } | null;
  } | null;
};

export type PlansQueryVariables = Exact<{
  where?: InputMaybe<PlansWhereInput>;
}>;

export type PlansQuery = {
  __typename?: 'Query';
  plans: {
    __typename?: 'PlanResponse';
    items: Array<{
      __typename?: 'Plan';
      id: number | null;
      title: string | null;
      sub_title: string | null;
      price: string | null;
      description: string | null;
      helper_text: string | null;
      status: PlanStatuses | null;
      paypal: { __typename?: 'Paypal'; paypal_id: string | null } | null;
      open_pay: {
        __typename?: 'OpenPay';
        open_pay_id: string | null;
        first_payment_price: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type PlanQueryVariables = Exact<{
  planId?: InputMaybe<Scalars['Int']>;
}>;

export type PlanQuery = {
  __typename?: 'Query';
  plan: {
    __typename?: 'Plan';
    id: number | null;
    title: string | null;
    sub_title: string | null;
    price: string | null;
    description: string | null;
    helper_text: string | null;
    status: PlanStatuses | null;
    paypal: { __typename?: 'Paypal'; paypal_id: string | null } | null;
    open_pay: { __typename?: 'OpenPay'; open_pay_id: string | null } | null;
  } | null;
};

export type CancelSubscriptionMutationVariables = Exact<{
  data?: InputMaybe<CancelSubscriptionInput>;
}>;

export type CancelSubscriptionMutation = {
  __typename?: 'Mutation';
  cancelSubscription: {
    __typename?: 'CancelSubscriptionResponse';
    subscription: {
      __typename?: 'Subscription';
      cancel_date: string | null;
      period_start_date: string | null;
      period_end_date: string | null;
      is_cancel: boolean | null;
    } | null;
  } | null;
};

export type SignupMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
}>;

export type SignupMutation = {
  __typename?: 'Mutation';
  signup: {
    __typename?: 'AuthPayload';
    token: string | null;
    user: { __typename?: 'User'; id: number } | null;
  } | null;
};

export type GoogleSignupMutationVariables = Exact<{
  token: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
}>;

export type GoogleSignupMutation = {
  __typename?: 'Mutation';
  googleSignup: {
    __typename?: 'AuthPayload';
    token: string | null;
    user: { __typename?: 'User'; id: number } | null;
  } | null;
};

export type RecaptchaMutationVariables = Exact<{
  email: Scalars['String'];
  token: Scalars['String'];
}>;

export type RecaptchaMutation = {
  __typename?: 'Mutation';
  recaptcha: {
    __typename?: 'RecaptchaResponse';
    success: boolean | null;
  } | null;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: 'Query';
  currentUser: {
    __typename?: 'User';
    id: number;
    name: string;
    email: string;
    avatar: string | null;
    gender: Gender | null;
    birthday: string | null;
    education: Education | null;
    social_providers: Array<{
      __typename?: 'UserSocialProviders';
      id: number;
      provider: SocialProviders | null;
      provider_id: string | null;
      primary: boolean | null;
    } | null> | null;
    role: {
      __typename?: 'UserRole';
      id: number | null;
      name: UserRoles | null;
    } | null;
    subscriptions: Array<{
      __typename?: 'Subscription';
      cancel_date: string | null;
      status: SubscriptionStatuses | null;
      payment_method: PaymentMethod | null;
      period_end_date: string | null;
      period_start_date: string | null;
      is_cancel: boolean | null;
      id: number | null;
      plan: {
        __typename?: 'Plan';
        title: string | null;
        description: string | null;
        sub_title: string | null;
        price: string | null;
      } | null;
    } | null> | null;
    invoices: Array<{
      __typename?: 'Invoice';
      id: number | null;
      transaction_id: string | null;
      status: InvoiceStatuses | null;
      created_at: string | null;
      updated_at: string | null;
      plan: {
        __typename?: 'Plan';
        title: string | null;
        description: string | null;
        sub_title: string | null;
        price: string | null;
      } | null;
    } | null> | null;
    card: {
      __typename?: 'Card';
      type: string | null;
      brand: string | null;
      card_number: string | null;
      holder_name: string | null;
      expiration_year: string | null;
      expiration_month: string | null;
      bank_name: string | null;
      bank_code: string | null;
      customer_card_id: string | null;
    } | null;
  } | null;
};

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  redirectUrl: Scalars['String'];
  form: Scalars['String'];
}>;

export type ForgotPasswordMutation = {
  __typename?: 'Mutation';
  forgotPassword: {
    __typename?: 'ForgotPasswordPayload';
    success: boolean | null;
  } | null;
};

export type ChangePasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
}>;

export type ChangePasswordMutation = {
  __typename?: 'Mutation';
  changePassword: {
    __typename?: 'AuthPayload';
    token: string | null;
    user: { __typename?: 'User'; id: number } | null;
  } | null;
};

export type UpdateUserMutationVariables = Exact<{
  data?: InputMaybe<UpdateUserInput>;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: number;
    name: string;
    email: string;
    avatar: string | null;
    gender: Gender | null;
    birthday: string | null;
  } | null;
};

export type FacebookSignupMutationVariables = Exact<{
  token: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
}>;

export type FacebookSignupMutation = {
  __typename?: 'Mutation';
  facebookSignup: {
    __typename?: 'AuthPayload';
    token: string | null;
    user: { __typename?: 'User'; id: number } | null;
  } | null;
};

export type AppleSignupMutationVariables = Exact<{
  token: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
}>;

export type AppleSignupMutation = {
  __typename?: 'Mutation';
  appleSignup: {
    __typename?: 'AuthPayload';
    token: string | null;
    user: { __typename?: 'User'; id: number } | null;
  } | null;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'AuthPayload';
    token: string | null;
    user: { __typename?: 'User'; id: number } | null;
  } | null;
};

export type UsersQueryVariables = Exact<{
  where?: InputMaybe<UsersWhere>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;

export type UsersQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UsersPayload';
    count: number | null;
    users: Array<{
      __typename?: 'User';
      id: number;
      name: string;
      email: string;
      avatar: string | null;
      gender: Gender | null;
      birthday: string | null;
      social_providers: Array<{
        __typename?: 'UserSocialProviders';
        id: number;
        provider: SocialProviders | null;
        provider_id: string | null;
      } | null> | null;
      role: {
        __typename?: 'UserRole';
        id: number | null;
        name: UserRoles | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ConnectUserGoogleMutationVariables = Exact<{
  data: ConnectUserGoogleInput;
}>;

export type ConnectUserGoogleMutation = {
  __typename?: 'Mutation';
  connectUserGoogle: {
    __typename?: 'ConnectResponse';
    token: string | null;
    user: {
      __typename?: 'User';
      id: number;
      name: string;
      email: string;
    } | null;
  } | null;
};

export type DisconnectUserProviderMutationVariables = Exact<{
  data: DisconnectUserProviderInput;
}>;

export type DisconnectUserProviderMutation = {
  __typename?: 'Mutation';
  disconnectUserProvider: {
    __typename?: 'DisconnectResponse';
    message: string | null;
  } | null;
};

export type ConnectUserFacebookMutationVariables = Exact<{
  data: ConnectUserFacebookInput;
}>;

export type ConnectUserFacebookMutation = {
  __typename?: 'Mutation';
  connectUserFacebook: {
    __typename?: 'ConnectResponse';
    token: string | null;
    user: {
      __typename?: 'User';
      id: number;
      name: string;
      email: string;
    } | null;
  } | null;
};

export type ConnectUserAppleMutationVariables = Exact<{
  data: ConnectUserAppleInput;
}>;

export type ConnectUserAppleMutation = {
  __typename?: 'Mutation';
  connectUserApple: {
    __typename?: 'ConnectResponse';
    token: string | null;
    user: {
      __typename?: 'User';
      id: number;
      name: string;
      email: string;
    } | null;
  } | null;
};

export type DisableAccountMutationVariables = Exact<{
  data?: InputMaybe<DisableUserInput>;
}>;

export type DisableAccountMutation = {
  __typename?: 'Mutation';
  disableAccount: {
    __typename?: 'disableAccountResponse';
    message: string | null;
    success: boolean | null;
  } | null;
};

export type UserSavedIterPostsQueryVariables = Exact<{
  where?: InputMaybe<UserSavedIterPostsWhere>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type UserSavedIterPostsQuery = {
  __typename?: 'Query';
  userSavedIterPosts: {
    __typename?: 'UserSavedIterPostsResponse';
    count: number | null;
    posts: Array<{
      __typename?: 'IterPost';
      id: string | null;
      type: string | null;
      readingTime: string | null;
      url: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      author: {
        __typename?: 'IterPostAuthor';
        id: string | null;
        name: string | null;
      } | null;
      topics: Array<{
        __typename?: 'IterPostTopic';
        id: string | null;
        name: string | null;
      } | null> | null;
      categories: Array<{
        __typename?: 'IterPostCategory';
        id: string | null;
        slug: string | null;
        name: string | null;
      } | null> | null;
      content: Array<{
        __typename?: 'IterPostComponent';
        name: IterPostComponentName | null;
        text: string | null;
        remoteContent: {
          __typename?: 'IterRemoteContent';
          href: string | null;
          rendition: string | null;
        } | null;
        component: Array<{
          __typename?: 'IterPostComponentContent';
          name: IterPostComponentName | null;
          text: string | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type UserSavedPostsQueryVariables = Exact<{
  where?: InputMaybe<UserSavedPostsWhere>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type UserSavedPostsQuery = {
  __typename?: 'Query';
  userSavedPosts: {
    __typename?: 'UserSavedPostsResponse';
    count: number | null;
    posts: Array<{
      __typename?: 'UserSavedPostRestApi';
      id: number | null;
      post_id: number | null;
      post_title: string | null;
      post_type: string | null;
      post_content: string | null;
      post_url: string | null;
      post_uri: string | null;
      post_date: string | null;
      post_image: string | null;
      post_name: string | null;
      post_terms: Array<string | null> | null;
      sub_post_type: string | null;
    } | null> | null;
  } | null;
};

export type CreateUserSavedPostMutationVariables = Exact<{
  data?: InputMaybe<CreateUserSavedPostInput>;
}>;

export type CreateUserSavedPostMutation = {
  __typename?: 'Mutation';
  createUserSavedPost: {
    __typename?: 'UserSavedPost';
    id: number | null;
  } | null;
};

export type DeleteUserSavedPostMutationVariables = Exact<{
  data?: InputMaybe<DeleteUserSavedPostInput>;
}>;

export type DeleteUserSavedPostMutation = {
  __typename?: 'Mutation';
  deleteUserSavedPost: {
    __typename?: 'UserSavedPost';
    id: number | null;
  } | null;
};

export type UpdateUserEmailMutationVariables = Exact<{
  data?: InputMaybe<UpdateUserEmailInput>;
}>;

export type UpdateUserEmailMutation = {
  __typename?: 'Mutation';
  updateUserEmail: {
    __typename?: 'UpdateUserEmailResponse';
    message: string | null;
    success: boolean | null;
  } | null;
};

export const UpdateCardDocument = gql`
  mutation UpdateCard($data: UpdateCardInput) {
    updateCard(data: $data) {
      card {
        type
        brand
        card_number
        holder_name
        expiration_year
        expiration_month
        bank_name
        bank_code
      }
    }
  }
`;
export type UpdateCardMutationFn = Apollo.MutationFunction<
  UpdateCardMutation,
  UpdateCardMutationVariables
>;

/**
 * __useUpdateCardMutation__
 *
 * To run a mutation, you first call `useUpdateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardMutation, { data, loading, error }] = useUpdateCardMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCardMutation,
    UpdateCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCardMutation, UpdateCardMutationVariables>(
    UpdateCardDocument,
    options,
  );
}
export type UpdateCardMutationHookResult = ReturnType<
  typeof useUpdateCardMutation
>;
export type UpdateCardMutationResult =
  Apollo.MutationResult<UpdateCardMutation>;
export type UpdateCardMutationOptions = Apollo.BaseMutationOptions<
  UpdateCardMutation,
  UpdateCardMutationVariables
>;
export const MailchimpAddSubscriptionDocument = gql`
  mutation MailchimpAddSubscription($data: MailchimpAddSubscriptionInput) {
    mailchimpAddSubscription(data: $data) {
      success
    }
  }
`;
export type MailchimpAddSubscriptionMutationFn = Apollo.MutationFunction<
  MailchimpAddSubscriptionMutation,
  MailchimpAddSubscriptionMutationVariables
>;

/**
 * __useMailchimpAddSubscriptionMutation__
 *
 * To run a mutation, you first call `useMailchimpAddSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMailchimpAddSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mailchimpAddSubscriptionMutation, { data, loading, error }] = useMailchimpAddSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMailchimpAddSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MailchimpAddSubscriptionMutation,
    MailchimpAddSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MailchimpAddSubscriptionMutation,
    MailchimpAddSubscriptionMutationVariables
  >(MailchimpAddSubscriptionDocument, options);
}
export type MailchimpAddSubscriptionMutationHookResult = ReturnType<
  typeof useMailchimpAddSubscriptionMutation
>;
export type MailchimpAddSubscriptionMutationResult =
  Apollo.MutationResult<MailchimpAddSubscriptionMutation>;
export type MailchimpAddSubscriptionMutationOptions =
  Apollo.BaseMutationOptions<
    MailchimpAddSubscriptionMutation,
    MailchimpAddSubscriptionMutationVariables
  >;
export const MailchimpAudiencesDocument = gql`
  query MailchimpAudiences($where: MailchimpSubscriptionWhere) {
    mailchimpAudiences {
      count
      items {
        audience
        id
        is_subscribed
        name
        description
      }
    }
    mailchimpSubscriptions(where: $where) {
      subscriptions {
        unique_email_id
        status
        audience
      }
    }
  }
`;

/**
 * __useMailchimpAudiencesQuery__
 *
 * To run a query within a React component, call `useMailchimpAudiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMailchimpAudiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMailchimpAudiencesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMailchimpAudiencesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MailchimpAudiencesQuery,
    MailchimpAudiencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MailchimpAudiencesQuery,
    MailchimpAudiencesQueryVariables
  >(MailchimpAudiencesDocument, options);
}
export function useMailchimpAudiencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MailchimpAudiencesQuery,
    MailchimpAudiencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MailchimpAudiencesQuery,
    MailchimpAudiencesQueryVariables
  >(MailchimpAudiencesDocument, options);
}
export type MailchimpAudiencesQueryHookResult = ReturnType<
  typeof useMailchimpAudiencesQuery
>;
export type MailchimpAudiencesLazyQueryHookResult = ReturnType<
  typeof useMailchimpAudiencesLazyQuery
>;
export type MailchimpAudiencesQueryResult = Apollo.QueryResult<
  MailchimpAudiencesQuery,
  MailchimpAudiencesQueryVariables
>;
export const NotificationsDocument = gql`
  query Notifications($where: notificationsWhere, $page: Int, $pageSize: Int) {
    notificationList(where: $where, page: $page, pageSize: $pageSize) {
      notifications {
        id
        message
        created_at
      }
      count
    }
  }
`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options,
  );
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options,
  );
}
export type NotificationsQueryHookResult = ReturnType<
  typeof useNotificationsQuery
>;
export type NotificationsLazyQueryHookResult = ReturnType<
  typeof useNotificationsLazyQuery
>;
export type NotificationsQueryResult = Apollo.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>;
export const CreateOpenPaySubscriptionDocument = gql`
  mutation CreateOpenPaySubscription($data: CreateOpenPaySubscriptionInput) {
    createOpenPaySubscription(data: $data) {
      subscription {
        id
        status
        customer_id
        period_end_date
        period_start_date
        created_at
        updated_at
      }
    }
  }
`;
export type CreateOpenPaySubscriptionMutationFn = Apollo.MutationFunction<
  CreateOpenPaySubscriptionMutation,
  CreateOpenPaySubscriptionMutationVariables
>;

/**
 * __useCreateOpenPaySubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateOpenPaySubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpenPaySubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpenPaySubscriptionMutation, { data, loading, error }] = useCreateOpenPaySubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOpenPaySubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOpenPaySubscriptionMutation,
    CreateOpenPaySubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOpenPaySubscriptionMutation,
    CreateOpenPaySubscriptionMutationVariables
  >(CreateOpenPaySubscriptionDocument, options);
}
export type CreateOpenPaySubscriptionMutationHookResult = ReturnType<
  typeof useCreateOpenPaySubscriptionMutation
>;
export type CreateOpenPaySubscriptionMutationResult =
  Apollo.MutationResult<CreateOpenPaySubscriptionMutation>;
export type CreateOpenPaySubscriptionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateOpenPaySubscriptionMutation,
    CreateOpenPaySubscriptionMutationVariables
  >;
export const CreateOpenPayChargeDocument = gql`
  mutation CreateOpenPayCharge($data: CreateOpenPayChargeInput) {
    createOpenPayCharge(data: $data) {
      redirect_url
    }
  }
`;
export type CreateOpenPayChargeMutationFn = Apollo.MutationFunction<
  CreateOpenPayChargeMutation,
  CreateOpenPayChargeMutationVariables
>;

/**
 * __useCreateOpenPayChargeMutation__
 *
 * To run a mutation, you first call `useCreateOpenPayChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpenPayChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpenPayChargeMutation, { data, loading, error }] = useCreateOpenPayChargeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOpenPayChargeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOpenPayChargeMutation,
    CreateOpenPayChargeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOpenPayChargeMutation,
    CreateOpenPayChargeMutationVariables
  >(CreateOpenPayChargeDocument, options);
}
export type CreateOpenPayChargeMutationHookResult = ReturnType<
  typeof useCreateOpenPayChargeMutation
>;
export type CreateOpenPayChargeMutationResult =
  Apollo.MutationResult<CreateOpenPayChargeMutation>;
export type CreateOpenPayChargeMutationOptions = Apollo.BaseMutationOptions<
  CreateOpenPayChargeMutation,
  CreateOpenPayChargeMutationVariables
>;
export const CreatePaypalSubscriptionDocument = gql`
  mutation CreatePaypalSubscription($data: CreatePaypalSubscriptionInput) {
    createPaypalSubscription(data: $data) {
      subscription {
        id
        status
        customer_id
        period_end_date
        period_start_date
        cancel_date
        is_cancel
        payment_method
        created_at
        updated_at
      }
    }
  }
`;
export type CreatePaypalSubscriptionMutationFn = Apollo.MutationFunction<
  CreatePaypalSubscriptionMutation,
  CreatePaypalSubscriptionMutationVariables
>;

/**
 * __useCreatePaypalSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreatePaypalSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaypalSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaypalSubscriptionMutation, { data, loading, error }] = useCreatePaypalSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePaypalSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaypalSubscriptionMutation,
    CreatePaypalSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePaypalSubscriptionMutation,
    CreatePaypalSubscriptionMutationVariables
  >(CreatePaypalSubscriptionDocument, options);
}
export type CreatePaypalSubscriptionMutationHookResult = ReturnType<
  typeof useCreatePaypalSubscriptionMutation
>;
export type CreatePaypalSubscriptionMutationResult =
  Apollo.MutationResult<CreatePaypalSubscriptionMutation>;
export type CreatePaypalSubscriptionMutationOptions =
  Apollo.BaseMutationOptions<
    CreatePaypalSubscriptionMutation,
    CreatePaypalSubscriptionMutationVariables
  >;
export const PlansDocument = gql`
  query Plans($where: PlansWhereInput) {
    plans(where: $where) {
      items {
        id
        title
        sub_title
        price
        description
        helper_text
        paypal {
          paypal_id
        }
        status
        open_pay {
          open_pay_id
          first_payment_price
        }
      }
    }
  }
`;

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePlansQuery(
  baseOptions?: Apollo.QueryHookOptions<PlansQuery, PlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlansQuery, PlansQueryVariables>(
    PlansDocument,
    options,
  );
}
export function usePlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PlansQuery, PlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlansQuery, PlansQueryVariables>(
    PlansDocument,
    options,
  );
}
export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>;
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>;
export type PlansQueryResult = Apollo.QueryResult<
  PlansQuery,
  PlansQueryVariables
>;
export const PlanDocument = gql`
  query Plan($planId: Int) {
    plan(id: $planId) {
      id
      title
      sub_title
      price
      description
      helper_text
      paypal {
        paypal_id
      }
      status
      open_pay {
        open_pay_id
      }
    }
  }
`;

/**
 * __usePlanQuery__
 *
 * To run a query within a React component, call `usePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function usePlanQuery(
  baseOptions?: Apollo.QueryHookOptions<PlanQuery, PlanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlanQuery, PlanQueryVariables>(PlanDocument, options);
}
export function usePlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PlanQuery, PlanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlanQuery, PlanQueryVariables>(
    PlanDocument,
    options,
  );
}
export type PlanQueryHookResult = ReturnType<typeof usePlanQuery>;
export type PlanLazyQueryHookResult = ReturnType<typeof usePlanLazyQuery>;
export type PlanQueryResult = Apollo.QueryResult<PlanQuery, PlanQueryVariables>;
export const CancelSubscriptionDocument = gql`
  mutation CancelSubscription($data: CancelSubscriptionInput) {
    cancelSubscription(data: $data) {
      subscription {
        cancel_date
        period_start_date
        period_end_date
        is_cancel
      }
    }
  }
`;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >(CancelSubscriptionDocument, options);
}
export type CancelSubscriptionMutationHookResult = ReturnType<
  typeof useCancelSubscriptionMutation
>;
export type CancelSubscriptionMutationResult =
  Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;
export const SignupDocument = gql`
  mutation Signup(
    $email: String!
    $password: String!
    $name: String!
    $url: String!
  ) {
    signup(email: $email, password: $password, name: $name, url: $url) {
      user {
        id
      }
      token
    }
  }
`;
export type SignupMutationFn = Apollo.MutationFunction<
  SignupMutation,
  SignupMutationVariables
>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      name: // value for 'name'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupMutation,
    SignupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignupMutation, SignupMutationVariables>(
    SignupDocument,
    options,
  );
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<
  SignupMutation,
  SignupMutationVariables
>;
export const GoogleSignupDocument = gql`
  mutation googleSignup($token: String!, $url: String) {
    googleSignup(token: $token, url: $url) {
      user {
        id
      }
      token
    }
  }
`;
export type GoogleSignupMutationFn = Apollo.MutationFunction<
  GoogleSignupMutation,
  GoogleSignupMutationVariables
>;

/**
 * __useGoogleSignupMutation__
 *
 * To run a mutation, you first call `useGoogleSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleSignupMutation, { data, loading, error }] = useGoogleSignupMutation({
 *   variables: {
 *      token: // value for 'token'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useGoogleSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GoogleSignupMutation,
    GoogleSignupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GoogleSignupMutation,
    GoogleSignupMutationVariables
  >(GoogleSignupDocument, options);
}
export type GoogleSignupMutationHookResult = ReturnType<
  typeof useGoogleSignupMutation
>;
export type GoogleSignupMutationResult =
  Apollo.MutationResult<GoogleSignupMutation>;
export type GoogleSignupMutationOptions = Apollo.BaseMutationOptions<
  GoogleSignupMutation,
  GoogleSignupMutationVariables
>;
export const RecaptchaDocument = gql`
  mutation Recaptcha($email: String!, $token: String!) {
    recaptcha(email: $email, token: $token) {
      success
    }
  }
`;
export type RecaptchaMutationFn = Apollo.MutationFunction<
  RecaptchaMutation,
  RecaptchaMutationVariables
>;

/**
 * __useRecaptchaMutation__
 *
 * To run a mutation, you first call `useRecaptchaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecaptchaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recaptchaMutation, { data, loading, error }] = useRecaptchaMutation({
 *   variables: {
 *      email: // value for 'email'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRecaptchaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RecaptchaMutation,
    RecaptchaMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RecaptchaMutation, RecaptchaMutationVariables>(
    RecaptchaDocument,
    options,
  );
}
export type RecaptchaMutationHookResult = ReturnType<
  typeof useRecaptchaMutation
>;
export type RecaptchaMutationResult = Apollo.MutationResult<RecaptchaMutation>;
export type RecaptchaMutationOptions = Apollo.BaseMutationOptions<
  RecaptchaMutation,
  RecaptchaMutationVariables
>;
export const CurrentUserDocument = gql`
  query CurrentUser {
    currentUser {
      id
      name
      email
      avatar
      gender
      birthday
      education
      social_providers {
        id
        provider
        provider_id
        primary
      }
      role {
        id
        name
      }
      subscriptions {
        plan {
          title
          description
          sub_title
          price
        }
        cancel_date
        status
        payment_method
        period_end_date
        period_start_date
        is_cancel
        id
      }
      invoices {
        id
        transaction_id
        status
        created_at
        updated_at
        plan {
          title
          description
          sub_title
          price
        }
      }
      card {
        type
        brand
        card_number
        holder_name
        expiration_year
        expiration_month
        bank_name
        bank_code
        customer_card_id
      }
    }
  }
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options,
  );
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options,
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export const ForgotPasswordDocument = gql`
  mutation ForgotPassword(
    $email: String!
    $redirectUrl: String!
    $form: String!
  ) {
    forgotPassword(email: $email, redirectUrl: $redirectUrl, form: $form) {
      success
    }
  }
`;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      redirectUrl: // value for 'redirectUrl'
 *      form: // value for 'form'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >(ForgotPasswordDocument, options);
}
export type ForgotPasswordMutationHookResult = ReturnType<
  typeof useForgotPasswordMutation
>;
export type ForgotPasswordMutationResult =
  Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword(
    $email: String!
    $password: String!
    $token: String!
  ) {
    changePassword(email: $email, password: $password, token: $token) {
      user {
        id
      }
      token
    }
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>;
export type ChangePasswordMutationResult =
  Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($data: UpdateUserInput) {
    updateUser(data: $data) {
      id
      name
      email
      avatar
      gender
      birthday
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const FacebookSignupDocument = gql`
  mutation FacebookSignup($token: String!, $url: String) {
    facebookSignup(token: $token, url: $url) {
      user {
        id
      }
      token
    }
  }
`;
export type FacebookSignupMutationFn = Apollo.MutationFunction<
  FacebookSignupMutation,
  FacebookSignupMutationVariables
>;

/**
 * __useFacebookSignupMutation__
 *
 * To run a mutation, you first call `useFacebookSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFacebookSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [facebookSignupMutation, { data, loading, error }] = useFacebookSignupMutation({
 *   variables: {
 *      token: // value for 'token'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useFacebookSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FacebookSignupMutation,
    FacebookSignupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FacebookSignupMutation,
    FacebookSignupMutationVariables
  >(FacebookSignupDocument, options);
}
export type FacebookSignupMutationHookResult = ReturnType<
  typeof useFacebookSignupMutation
>;
export type FacebookSignupMutationResult =
  Apollo.MutationResult<FacebookSignupMutation>;
export type FacebookSignupMutationOptions = Apollo.BaseMutationOptions<
  FacebookSignupMutation,
  FacebookSignupMutationVariables
>;
export const AppleSignupDocument = gql`
  mutation AppleSignup($token: String!, $url: String) {
    appleSignup(token: $token, url: $url) {
      user {
        id
      }
      token
    }
  }
`;
export type AppleSignupMutationFn = Apollo.MutationFunction<
  AppleSignupMutation,
  AppleSignupMutationVariables
>;

/**
 * __useAppleSignupMutation__
 *
 * To run a mutation, you first call `useAppleSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppleSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appleSignupMutation, { data, loading, error }] = useAppleSignupMutation({
 *   variables: {
 *      token: // value for 'token'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useAppleSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AppleSignupMutation,
    AppleSignupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AppleSignupMutation, AppleSignupMutationVariables>(
    AppleSignupDocument,
    options,
  );
}
export type AppleSignupMutationHookResult = ReturnType<
  typeof useAppleSignupMutation
>;
export type AppleSignupMutationResult =
  Apollo.MutationResult<AppleSignupMutation>;
export type AppleSignupMutationOptions = Apollo.BaseMutationOptions<
  AppleSignupMutation,
  AppleSignupMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        id
      }
      token
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const UsersDocument = gql`
  query users($where: UsersWhere, $limit: Int, $skip: Int) {
    users(where: $where, limit: $limit, skip: $skip) {
      users {
        id
        name
        email
        avatar
        gender
        birthday
        social_providers {
          id
          provider
          provider_id
        }
        role {
          id
          name
        }
      }
      count
    }
  }
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options,
  );
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options,
  );
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<
  UsersQuery,
  UsersQueryVariables
>;
export const ConnectUserGoogleDocument = gql`
  mutation ConnectUserGoogle($data: ConnectUserGoogleInput!) {
    connectUserGoogle(data: $data) {
      token
      user {
        id
        name
        email
      }
    }
  }
`;
export type ConnectUserGoogleMutationFn = Apollo.MutationFunction<
  ConnectUserGoogleMutation,
  ConnectUserGoogleMutationVariables
>;

/**
 * __useConnectUserGoogleMutation__
 *
 * To run a mutation, you first call `useConnectUserGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectUserGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectUserGoogleMutation, { data, loading, error }] = useConnectUserGoogleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useConnectUserGoogleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectUserGoogleMutation,
    ConnectUserGoogleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConnectUserGoogleMutation,
    ConnectUserGoogleMutationVariables
  >(ConnectUserGoogleDocument, options);
}
export type ConnectUserGoogleMutationHookResult = ReturnType<
  typeof useConnectUserGoogleMutation
>;
export type ConnectUserGoogleMutationResult =
  Apollo.MutationResult<ConnectUserGoogleMutation>;
export type ConnectUserGoogleMutationOptions = Apollo.BaseMutationOptions<
  ConnectUserGoogleMutation,
  ConnectUserGoogleMutationVariables
>;
export const DisconnectUserProviderDocument = gql`
  mutation DisconnectUserProvider($data: DisconnectUserProviderInput!) {
    disconnectUserProvider(data: $data) {
      message
    }
  }
`;
export type DisconnectUserProviderMutationFn = Apollo.MutationFunction<
  DisconnectUserProviderMutation,
  DisconnectUserProviderMutationVariables
>;

/**
 * __useDisconnectUserProviderMutation__
 *
 * To run a mutation, you first call `useDisconnectUserProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectUserProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectUserProviderMutation, { data, loading, error }] = useDisconnectUserProviderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDisconnectUserProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectUserProviderMutation,
    DisconnectUserProviderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisconnectUserProviderMutation,
    DisconnectUserProviderMutationVariables
  >(DisconnectUserProviderDocument, options);
}
export type DisconnectUserProviderMutationHookResult = ReturnType<
  typeof useDisconnectUserProviderMutation
>;
export type DisconnectUserProviderMutationResult =
  Apollo.MutationResult<DisconnectUserProviderMutation>;
export type DisconnectUserProviderMutationOptions = Apollo.BaseMutationOptions<
  DisconnectUserProviderMutation,
  DisconnectUserProviderMutationVariables
>;
export const ConnectUserFacebookDocument = gql`
  mutation ConnectUserFacebook($data: ConnectUserFacebookInput!) {
    connectUserFacebook(data: $data) {
      token
      user {
        id
        name
        email
      }
    }
  }
`;
export type ConnectUserFacebookMutationFn = Apollo.MutationFunction<
  ConnectUserFacebookMutation,
  ConnectUserFacebookMutationVariables
>;

/**
 * __useConnectUserFacebookMutation__
 *
 * To run a mutation, you first call `useConnectUserFacebookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectUserFacebookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectUserFacebookMutation, { data, loading, error }] = useConnectUserFacebookMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useConnectUserFacebookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectUserFacebookMutation,
    ConnectUserFacebookMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConnectUserFacebookMutation,
    ConnectUserFacebookMutationVariables
  >(ConnectUserFacebookDocument, options);
}
export type ConnectUserFacebookMutationHookResult = ReturnType<
  typeof useConnectUserFacebookMutation
>;
export type ConnectUserFacebookMutationResult =
  Apollo.MutationResult<ConnectUserFacebookMutation>;
export type ConnectUserFacebookMutationOptions = Apollo.BaseMutationOptions<
  ConnectUserFacebookMutation,
  ConnectUserFacebookMutationVariables
>;
export const ConnectUserAppleDocument = gql`
  mutation ConnectUserApple($data: ConnectUserAppleInput!) {
    connectUserApple(data: $data) {
      token
      user {
        id
        name
        email
      }
    }
  }
`;
export type ConnectUserAppleMutationFn = Apollo.MutationFunction<
  ConnectUserAppleMutation,
  ConnectUserAppleMutationVariables
>;

/**
 * __useConnectUserAppleMutation__
 *
 * To run a mutation, you first call `useConnectUserAppleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectUserAppleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectUserAppleMutation, { data, loading, error }] = useConnectUserAppleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useConnectUserAppleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectUserAppleMutation,
    ConnectUserAppleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConnectUserAppleMutation,
    ConnectUserAppleMutationVariables
  >(ConnectUserAppleDocument, options);
}
export type ConnectUserAppleMutationHookResult = ReturnType<
  typeof useConnectUserAppleMutation
>;
export type ConnectUserAppleMutationResult =
  Apollo.MutationResult<ConnectUserAppleMutation>;
export type ConnectUserAppleMutationOptions = Apollo.BaseMutationOptions<
  ConnectUserAppleMutation,
  ConnectUserAppleMutationVariables
>;
export const DisableAccountDocument = gql`
  mutation DisableAccount($data: DisableUserInput) {
    disableAccount(data: $data) {
      message
      success
    }
  }
`;
export type DisableAccountMutationFn = Apollo.MutationFunction<
  DisableAccountMutation,
  DisableAccountMutationVariables
>;

/**
 * __useDisableAccountMutation__
 *
 * To run a mutation, you first call `useDisableAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableAccountMutation, { data, loading, error }] = useDisableAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDisableAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisableAccountMutation,
    DisableAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisableAccountMutation,
    DisableAccountMutationVariables
  >(DisableAccountDocument, options);
}
export type DisableAccountMutationHookResult = ReturnType<
  typeof useDisableAccountMutation
>;
export type DisableAccountMutationResult =
  Apollo.MutationResult<DisableAccountMutation>;
export type DisableAccountMutationOptions = Apollo.BaseMutationOptions<
  DisableAccountMutation,
  DisableAccountMutationVariables
>;
export const UserSavedIterPostsDocument = gql`
  query userSavedIterPosts(
    $where: UserSavedIterPostsWhere
    $offset: Int
    $limit: Int
  ) {
    userSavedIterPosts(where: $where, offset: $offset, limit: $limit) {
      posts {
        id
        author {
          id
          name
        }
        topics {
          id
          name
        }
        categories {
          id
          slug
          name
        }
        type
        content {
          name
          text
          remoteContent {
            href
            rendition
          }
          component {
            name
            text
          }
        }
        readingTime
        url
        createdAt
        updatedAt
      }
      count
    }
  }
`;

/**
 * __useUserSavedIterPostsQuery__
 *
 * To run a query within a React component, call `useUserSavedIterPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSavedIterPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSavedIterPostsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUserSavedIterPostsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserSavedIterPostsQuery,
    UserSavedIterPostsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserSavedIterPostsQuery,
    UserSavedIterPostsQueryVariables
  >(UserSavedIterPostsDocument, options);
}
export function useUserSavedIterPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSavedIterPostsQuery,
    UserSavedIterPostsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserSavedIterPostsQuery,
    UserSavedIterPostsQueryVariables
  >(UserSavedIterPostsDocument, options);
}
export type UserSavedIterPostsQueryHookResult = ReturnType<
  typeof useUserSavedIterPostsQuery
>;
export type UserSavedIterPostsLazyQueryHookResult = ReturnType<
  typeof useUserSavedIterPostsLazyQuery
>;
export type UserSavedIterPostsQueryResult = Apollo.QueryResult<
  UserSavedIterPostsQuery,
  UserSavedIterPostsQueryVariables
>;
export const UserSavedPostsDocument = gql`
  query UserSavedPosts(
    $where: UserSavedPostsWhere
    $page: Int
    $pageSize: Int
  ) {
    userSavedPosts(where: $where, page: $page, pageSize: $pageSize) {
      posts {
        id
        post_id
        post_title
        post_type
        post_content
        post_url
        post_uri
        post_date
        post_image
        post_name
        post_terms
        sub_post_type
      }
      count
    }
  }
`;

/**
 * __useUserSavedPostsQuery__
 *
 * To run a query within a React component, call `useUserSavedPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSavedPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSavedPostsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useUserSavedPostsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserSavedPostsQuery,
    UserSavedPostsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserSavedPostsQuery, UserSavedPostsQueryVariables>(
    UserSavedPostsDocument,
    options,
  );
}
export function useUserSavedPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSavedPostsQuery,
    UserSavedPostsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserSavedPostsQuery, UserSavedPostsQueryVariables>(
    UserSavedPostsDocument,
    options,
  );
}
export type UserSavedPostsQueryHookResult = ReturnType<
  typeof useUserSavedPostsQuery
>;
export type UserSavedPostsLazyQueryHookResult = ReturnType<
  typeof useUserSavedPostsLazyQuery
>;
export type UserSavedPostsQueryResult = Apollo.QueryResult<
  UserSavedPostsQuery,
  UserSavedPostsQueryVariables
>;
export const CreateUserSavedPostDocument = gql`
  mutation CreateUserSavedPost($data: CreateUserSavedPostInput) {
    createUserSavedPost(data: $data) {
      id
    }
  }
`;
export type CreateUserSavedPostMutationFn = Apollo.MutationFunction<
  CreateUserSavedPostMutation,
  CreateUserSavedPostMutationVariables
>;

/**
 * __useCreateUserSavedPostMutation__
 *
 * To run a mutation, you first call `useCreateUserSavedPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSavedPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSavedPostMutation, { data, loading, error }] = useCreateUserSavedPostMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserSavedPostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserSavedPostMutation,
    CreateUserSavedPostMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserSavedPostMutation,
    CreateUserSavedPostMutationVariables
  >(CreateUserSavedPostDocument, options);
}
export type CreateUserSavedPostMutationHookResult = ReturnType<
  typeof useCreateUserSavedPostMutation
>;
export type CreateUserSavedPostMutationResult =
  Apollo.MutationResult<CreateUserSavedPostMutation>;
export type CreateUserSavedPostMutationOptions = Apollo.BaseMutationOptions<
  CreateUserSavedPostMutation,
  CreateUserSavedPostMutationVariables
>;
export const DeleteUserSavedPostDocument = gql`
  mutation DeleteUserSavedPost($data: DeleteUserSavedPostInput) {
    deleteUserSavedPost(data: $data) {
      id
    }
  }
`;
export type DeleteUserSavedPostMutationFn = Apollo.MutationFunction<
  DeleteUserSavedPostMutation,
  DeleteUserSavedPostMutationVariables
>;

/**
 * __useDeleteUserSavedPostMutation__
 *
 * To run a mutation, you first call `useDeleteUserSavedPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSavedPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSavedPostMutation, { data, loading, error }] = useDeleteUserSavedPostMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteUserSavedPostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserSavedPostMutation,
    DeleteUserSavedPostMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserSavedPostMutation,
    DeleteUserSavedPostMutationVariables
  >(DeleteUserSavedPostDocument, options);
}
export type DeleteUserSavedPostMutationHookResult = ReturnType<
  typeof useDeleteUserSavedPostMutation
>;
export type DeleteUserSavedPostMutationResult =
  Apollo.MutationResult<DeleteUserSavedPostMutation>;
export type DeleteUserSavedPostMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserSavedPostMutation,
  DeleteUserSavedPostMutationVariables
>;
export const UpdateUserEmailDocument = gql`
  mutation UpdateUserEmail($data: UpdateUserEmailInput) {
    updateUserEmail(data: $data) {
      message
      success
    }
  }
`;
export type UpdateUserEmailMutationFn = Apollo.MutationFunction<
  UpdateUserEmailMutation,
  UpdateUserEmailMutationVariables
>;

/**
 * __useUpdateUserEmailMutation__
 *
 * To run a mutation, you first call `useUpdateUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEmailMutation, { data, loading, error }] = useUpdateUserEmailMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserEmailMutation,
    UpdateUserEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserEmailMutation,
    UpdateUserEmailMutationVariables
  >(UpdateUserEmailDocument, options);
}
export type UpdateUserEmailMutationHookResult = ReturnType<
  typeof useUpdateUserEmailMutation
>;
export type UpdateUserEmailMutationResult =
  Apollo.MutationResult<UpdateUserEmailMutation>;
export type UpdateUserEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserEmailMutation,
  UpdateUserEmailMutationVariables
>;
