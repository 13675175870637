import React from 'react';
import { PlanItem } from './PlanItem';
import { Plan } from '../../../shared/types/generated';
import { createStyles, makeStyles } from '@mui/styles';
import { Box, Theme, Typography } from '@mui/material';
import { useAuth } from '../../auth/auth-hook';
import { useNavigate } from 'react-router-dom';
import { PlanLoading } from './PlanLoading';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    title: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '25px 0 10px 0',
      '& > .MuiTypography-root': {
        fontSize: 22,
        color: '#00B536',
      },
    },
    container: {
      border: '1px solid #9FE3B3',
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      minHeight: 500,
    },
    subtitle: {
      marginBottom: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > .MuiTypography-root': {
        color: '#00B536',
        backgroundColor: '#D7F1DF',
        padding: '0 10px',
        borderRadius: 15,
      },
    },
    price: {
      marginBottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    description: {
      marginBottom: 5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > .MuiTypography-root': {
        fontSize: 22,
        fontWeight: 700,
      },
    },
    button: {
      paddingTop: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 15,
      '& > .MuiButton-root': {
        background: '#00B536!important',
        color: 'white!important',
        width: '180px',
        height: 45,
      },
    },
    text: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > .MuiTypography-root': {
        textAlign: 'center',
      },
    },
  }),
);

type PlanItemThreeProps = {
  plan?: Plan;
  loading?: boolean;
};

export const PlanItemThree: React.FC<PlanItemThreeProps> = ({
  plan,
  loading,
}) => {
  const classes = useStyles();
  const { user } = useAuth();
  const navigate = useNavigate();

  if (loading) return <PlanLoading containerClassName={classes.container} />;

  if (!plan) return null;

  return (
    <PlanItem
      titleClassName={classes.title}
      containerClassName={classes.container}
      subTitleClassName={classes.subtitle}
      priceClassName={classes.price}
      descriptionClassName={classes.description}
      buttonClassName={classes.button}
      textButton="SUSCRIBIRME"
      imageOnCenter="/manojulio.png"
      helpTextContent={
        <>
          <Box mb={'10px'} className={classes.text}>
            <Typography fontWeight={'700'} textAlign={'center'}>
              ¡Te ahorras el 25%!
            </Typography>
          </Box>
          <Box mb={'20px'} className={classes.text}>
            <Typography fontSize={12} fontWeight={'700'} textAlign={'center'}>
              !Cancela cuando quieras¡
            </Typography>
          </Box>
        </>
      }
      plan={plan}
      onClick={() => {
        if (user) navigate(`/metodos-de-pago/${plan?.id}`);
        else
          window.location.href = `${window.location.origin}/autenticacion?redirect_url=${window.location.origin}/metodos-de-pago/${plan?.id}`;
      }}
    />
  );
};
