import React from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Hidden,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import PowerSettingsNewSharpIcon from '@mui/icons-material/PowerSettingsNewSharp';
import { useAuth } from '../../../../modules/auth/auth-hook';
import { useNavigate } from 'react-router-dom';
import { PRIMARY_COLOR } from '../../../css/theme';
import { RoleAuthorization } from '../../../../modules/rbac/RoleAuthorization';
import { RBACPermissions } from '../../../../modules/rbac/rbac-types';
import { openMobileMenu } from './user-actions';
import { openMobileMenuEvent } from './user-events';
import { useStore } from '@cobuildlab/react-simple-state';
import MenuIcon from '@mui/icons-material/Menu';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    toolbar: {
      backgroundColor: 'white',
      boxShadow: 'none',
      color: 'black',
    },
    cleanButton: {
      background: 'none!important',
      border: 'none!important',
      cursor: 'pointer',
      color: '#000!important',

      margin: '0 0',
    },
    menu: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      color: 'white!important',
      marginRight: '8px!important',
      //marginLeft: '5px!important',
    },
    textRed: {
      color: PRIMARY_COLOR,
    },
    groupButton: {
      borderBottom: '1px solid #999999!important',
      borderTop: '1px solid #CCCCCC!important',
      padding: '10px 0px 10px 0px',
      display: 'block!important',
    },
    iconButtonStyle: {
      padding: '0px 0px !important',
    },
    logoClasses: {
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      component: 'div',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        height: '100%',
        component: 'div',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      },
    },
  }),
);
type HeaderProps = {
  showSidebar?: boolean;
};
export const Header: React.FC<HeaderProps> = ({ showSidebar }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { user } = useAuth();
  const classes = useStyles();
  const navigate = useNavigate();
  const { open } = useStore(openMobileMenuEvent);
  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <AppBar>
        <Toolbar className={classes.toolbar}>
          <Box className={classes.logoClasses}>
            <Hidden mdDown={false} mdUp={true}>
              <a href="https://noroeste.com.mx">
                <img
                  src="/LOGO_NOROESTE.svg"
                  alt="logo"
                  width={100}
                  height={25}
                />
              </a>
            </Hidden>
            <Hidden mdDown={true} mdUp={false}>
              <a href="https://noroeste.com.mx">
                <img
                  src="/LOGO_NOROESTE_MOBILE.svg"
                  alt="logo"
                  width={155}
                  height={25}
                  style={{ flexGrow: 1 }}
                />
              </a>
            </Hidden>
          </Box>
          {showSidebar && (
            <Hidden mdDown={false} mdUp={true}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => openMobileMenu()}
                edge="start"
                sx={{ ...(open && { display: 'none' }) }}
              >
                <MenuIcon style={{ color: 'black' }} />
              </IconButton>
            </Hidden>
          )}
          <Box
            display={'flex'}
            justifyContent={'end'}
            width={'100%'}
            alignItems={'center'}
          >
            <RoleAuthorization
              render={() => (
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disableElevation
                  sx={{
                    textTransform: 'capitalize',
                    height: { xs: 30, md: 43 },
                    width: { xs: 80, md: 170 },
                    padding: '0px 0px !important',
                  }}
                  onClick={() => navigate('/suscripciones')}
                >
                  <Typography
                    lineHeight={{ xs: '10px', md: '14px' }}
                    fontSize={{ xs: '12px', md: '16px' }}
                  >
                    <Hidden lgDown={true} lgUp={false}>
                      Quiero{' '}
                    </Hidden>
                    acceso total
                  </Typography>
                </Button>
              )}
              permission={RBACPermissions.CAN_SUBSCRIPTION}
              error={() => null}
            />
            <div className={classes.menu}>
              <Hidden smDown={true} smUp={false}>
                <div>
                  {decodeURI(user?.name || '') || decodeURI(user?.email || '')}
                  &nbsp;&nbsp;
                </div>
              </Hidden>
              <IconButton
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                className={classes.iconButtonStyle}
              >
                <Avatar
                  style={{ padding: '0px 0px !important' }}
                  sx={{ width: 30, height: 30 }}
                  src={user?.avatar as string}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem>
                  <ListItemText>
                    Usuario:{' '}
                    <span className={classes.textRed}>
                      {decodeURI(user?.name as string)}
                    </span>{' '}
                  </ListItemText>
                </MenuItem>
                <div className={classes.groupButton}>
                  <MenuItem onClick={() => navigate('/')}> Mi perfil</MenuItem>
                  <MenuItem>
                    <a
                      style={{ textDecoration: 'none', color: 'black' }}
                      href="https://ac.noroeste.com.mx/autoayuda"
                      target="_blank"
                    >
                      Centro de ayuda
                    </a>
                  </MenuItem>
                </div>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <PowerSettingsNewSharpIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText onClick={() => navigate('/logout')}>
                    Cerrar sesión
                  </ListItemText>
                </MenuItem>
              </Menu>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
