import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import { PlanListItem } from './components/PlanListItem';
import { PlanDescriptionItem } from './components/PlanDescriptionItem';

import { useNavigate } from 'react-router-dom';
import { BackButton } from '../../shared/components/buttons/BackButton';
import { getRedirectUrl } from '../auth/auth-utils';

const PLAN_ITEM_LIST = [
  'Contenidos sin restricciones',
  'Reportajes e investigaciones exclusivas antes que nadie',
  'Resumen de noticias generado por Inteligencia Artificial',
  'Lectura de noticias por narrador electrónico',
  'Menos publicidad y mejor experiencia',
  'Acceso a nuestro periódico virtual epaper',
  <Box display={'inline-flex'}>
    Acceso a nuestras apps&ensp;
    <a
      target="_blank"
      href="https://itunes.apple.com/mx/app/noroeste/id1220184484?mt=8"
    >
      iOS
    </a>
    &ensp;y&ensp;
    <a
      target="_blank"
      href="https://play.google.com/store/apps/details?id=com.noroeste.norotest"
    >
      Android
    </a>
    .
  </Box>,
  'Invitaciones a eventos exclusivos y promociones',
];

export const Plans: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container style={{ padding: '10px 20px' }}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item md={12} width={'100%'}>
          <BackButton
            onClick={() => {
              const redirectUrl = getRedirectUrl();
              navigate(redirectUrl || '/');
            }}
          />
        </Grid>
        <Grid item md={9}>
          <Typography
            style={{ fontSize: 32, fontWeight: 'bold' }}
            align={'center'}
            mb={2}
          >
            Ayuda al periodismo independiente
          </Typography>
          <Typography style={{ fontSize: 17 }} align={'center'} mb={2}>
            Nos aseguramos de que recibas información de alta calidad y
            confiabilidad. También, evitamos la publicidad invasiva y clickbait,
            nos ayuda a cubrir gastos de personal e infraestructura tecnológica.
          </Typography>
          <Typography
            style={{ fontSize: 17, fontWeight: 'bold' }}
            align={'center'}
            mb={2}
          >
            Elige la suscripción ideal para ti y obtén acceso a todas nuestras
            publicaciones
          </Typography>
        </Grid>
      </Grid>
      <Box mt={'20px'}>
        <PlanListItem />
      </Box>
      <Box maxWidth={'400px'} margin={'0 auto'} paddingTop={'15px'}>
        {PLAN_ITEM_LIST.map((item) => (
          <PlanDescriptionItem value={item} />
        ))}
      </Box>
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <a
          target="_blank"
          href="https://play.google.com/store/apps/details?id=com.noroeste.norotest"
        >
          <img src="/download_google.png" alt="" width={150}></img>
        </a>
        &ensp;&ensp;
        <a
          target="_blank"
          href="https://itunes.apple.com/mx/app/noroeste/id1220184484?mt=8"
        >
          <img src="/download_ios.svg" alt="" width={130} />
        </a>
        .
      </Box>
      <Box marginTop={'50px'} color={'#777777'} paddingBottom={'20px'}>
        <Typography textAlign={{ xs: 'center', sm: 'start' }}>
          Si tienes alguna pregunta o inquietud, ponte en contacto con nosotros
          a través del correo electrónico: premium@noroeste.com. O bien,
          comunícate al{' '}
          <a
            href="tel:+526677598100"
            target="_blank"
            className="text-[#222222] text-[12px] font-Century-Gothic"
          >
            6677598100
          </a>{' '}
          o al{' '}
          <a
            href="tel:+526699155200"
            target="_blank"
            className="text-[#222222] text-[12px] font-Century-Gothic"
          >
            6699155200
          </a>
          , extensión: 100 de lunes a viernes de 8:00 a 16:00 hrs. Por WhatsApp
          puedes dejarnos un mensaje las 24 horas al{' '}
          <a
            href="https://api.whatsapp.com/send?phone=6699155200&text=%C2%A1Hola!%20%C2%BFMe%20podr%C3%ADan%20ayudar%3F%20Necesito%20m%C3%A1s%20informaci%C3%B3n"
            className="ml-1 w-[60px] h-[60px] drop-shadow-lg pointer-events-auto"
            target="_blank"
          >
            6699155200
          </a>
          .
        </Typography>
      </Box>
    </Container>
  );
};
