import React, { ReactNode } from 'react';
import { Typography } from '@mui/material';

type ItemProps = {
  value: string | ReactNode;
};
export const PlanDescriptionItem: React.FC<ItemProps> = ({ value }) => {
  return (
    <div style={{ display: 'flex', paddingBottom: '13px' }}>
      <img
        src="/check-red.png"
        alt="logo"
        width={30}
        height={25}
        style={{ display: 'flex', alignSelf: 'center' }}
      />
      <Typography
        ml={'5px'}
        fontWeight={'500'}
        fontSize={'16px'}
        display={'flex'}
        alignSelf={'center'}
      >
        {value}
      </Typography>
    </div>
  );
};
