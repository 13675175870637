import React from 'react';
import { Box } from '@mui/material';

export const HeaderAuth: React.FC = () => {
  return (
    <Box
      style={{ background: 'white' }}
      height={'75px'}
      justifyContent={'center'}
      alignItems={'center'}
      display={'flex'}
      width={'100%'}
    >
      <a href="https://noroeste.com.mx">
        <img
          src="/LOGO_NOROESTE.svg"
          alt="logo"
          width={225}
          height={25}
          style={{ flexGrow: 1 }}
        />
      </a>
    </Box>
  );
};
